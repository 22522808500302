.Tooltip {
    position: relative;

    &__contents {
        position: fixed;
        padding: 12px 14px 11px;
        background: white;
        font-size: 1em;
        text-align: center;
        border-radius: 6px;
        pointer-events: none;
        opacity: 0;
        transform: translate3d(-50%, -100%, 0);
        z-index: 1000;
        max-width: 30em;
        box-shadow: 0px 1px 7px 0px rgba(45, 53, 69, 0.25);;
        border: 1px solid white;

        div {
            -webkit-font-smoothing: antialiased;
            -webkit-transform: translateZ(0) scale(1.0, 1.0);
        }

        &__note {
            font-size: 0.86em;
            line-height: 1.27em;
            margin: 0.2em 0;
            opacity: 0.6;
        }

        &__arrow {
            // container to clip the box shadow of the arrow (rotated square)
            bottom: -8px;
            left: 50%;
            margin-left: -10px;
            margin-top: 0px;
            width: 21px;
            height: 20px;
            position: absolute;
            top: 100%;
            overflow: hidden;
            border-top: 0 solid white; //to hide the white border separating the triangle and container

            &::after {
                content: "";
                position: absolute;
                width: 9px;
                height: 9px;
                background: white;
                transform: translateX(-50%) translateY(-50%) rotate(45deg);
                top: 0;
                left: 50%;
                box-shadow: 0px 1px 7px 0px rgba(45, 53, 69, 0.25);;
            }
        }

        &__close {
            position: absolute;
            top: 0.6em;
            right: 0.6em;
            padding: 0.3em;
            opacity: 0.5;
            cursor: pointer;
            z-index: 10;
            transition: opacity 0.3s ease-out;

            &:hover {
                opacity: 1;
            }
        }

        &--showing {
            opacity: 1;
        }

        &--top {
            margin-top: -10px;
        }

        &--bottom {
            transform: translate3d(-50%, 0, 0);

            .Tooltip__contents__arrow {
                bottom: auto;
                top: -20px;
                transform-origin: center center;
                transform: rotate(-180deg);
            }
        }

        &--right {
            transform: translate3d(13px, -50%, 0);

            .Tooltip__contents__arrow {
                bottom: auto;
                top: 50%;
                left: -10px;
                margin-top: -10px;
                border-bottom-width: 8px;
                border-right-color: white;
                border-top-color: transparent;
                border-bottom-color: transparent;
                border-left-color: transparent;
                transform: rotate(90deg);
            }
        }

        &--left {
            transform: translate3d(0, -50%, 0);
            margin-left: -10px;
            margin-top: 0;

            .Tooltip__contents__arrow {
                bottom: auto;
                top: 50%;
                right: -20px;
                left: auto;
                margin-top: -10px;
                border-bottom-width: 10px;
                border-left-color: white;
                border-top-color: transparent;
                border-bottom-color: transparent;
                border-right-color: transparent;
                transform: rotate(-90deg);
            }
        }
    }
}
