// @import "src/styles/lib";

.Voting {
      position: relative;

  .App__button {
    position: absolute;
    top: -7.5em;
    right: 15em;
  }

  button {
      appearance: none;
      background: white;
      border-radius: 1em;
      padding: 0.5em 1em;
      font-weight: 600;
      border: none;
      cursor: pointer;

      &:hover {
          background: #eaeaea;
      }
  }

  &--is-in-pop-mode {
        cursor: crosshair;
  }

  &__overlay {
      display: flex;
      flex-wrap: wrap;
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border: 6px solid pink;
      pointer-events: none;
      z-index: 101;
      overflow: hidden;

    &--is-in-pop-mode {
        border-color: #7a3149;

        .Voting__overlay__text {
            color: white;
            background: #7a3149;

            /* working one */
            // cursor: url("data:image/svg+xml,%3Csvg  viewBox='0 0 100 125' id='pin-icon'%3E%3Cpath d='M58.29,75.781c1.561-2.367,2.252-4.963,1.984-7.211c-0.195-1.645-0.21-3.912,0.736-5.273l14.316-20.615  c0.945-1.36,1.764-2.411,1.83-2.346l0.117,0.118c1.818,1.818,4.947,1.797,7.774,0.191c1.44-0.816,1.425-2.766,0.253-3.938  L63.165,14.572c-1.171-1.172-3.121-1.188-3.938,0.252c-1.604,2.828-1.626,5.956,0.194,7.776c0,0,0.053,0.053,0.117,0.117  s-0.986,0.884-2.348,1.828L36.577,38.863c-1.361,0.945-3.628,0.932-5.273,0.736c-2.249-0.269-4.845,0.423-7.212,1.984  c-1.384,0.912-1.368,2.846-0.197,4.017l13.12,13.121L22.442,73.293l0.033,0.033c-5.84,6.154-9.539,11.666-8.439,12.766  s6.611-2.6,12.767-8.439l0.033,0.033l14.572-14.572l12.864,12.865C55.444,77.15,57.378,77.166,58.29,75.781z'/%3E %3C/svg%3E"), pointer;
        }
    }

    &__paused {
        height: 100%;
        width: 100%;
        background: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: rgba(#175898, 0.9);
        pointer-events: all;
        padding: 1em 2em;

        p {
            text-align: center;
        }

        button {
            font-size: 1.3em;
        }
    }

    button {
        margin-right: 1em;
    }

      &__text {
          position: absolute;
          bottom: 0;
          right: 0;
          left: 0;
          padding: 1em 1em;
          background: pink;
          color: #7a3149;
          font-weight: 500;
        //   overflow: hidden;
          z-index: 10000;
          pointer-events: all;
          display: flex;
          flex-wrap: wrap;
          align-items: center;

          &__text {
              margin-right: 1em;
            //   width: 10em;
              margin-left: auto;
              white-space: nowrap;
          }
      }

      &__timer {
          position: relative;
          flex: 1;
          width: 100%;
          height: 1em;
          margin: 0.3em 0;
          border: 3px solid white;
        //   overflow: hidden;
          cursor: pointer;
          min-width: 20em;

          &:before {
              content: "Time elapsed in the debate";
              position: absolute;
              left: 0.3em;
              z-index: 100;
              margin-top: -0.2em;
              opacity: 0;
              pointer-events: none;
              transition: all 0.3s ease-out;
          }

          &:hover:before {
              opacity: 0.5;
          }

          &__hover {
              position: absolute;
              top: -3.5em;
              left: 0;
              background: white;
              padding: 0.5em 1em;
              z-index: 1000;
              border-radius: 0.5em;
              width: 4em;
              left: -2em;
              text-align: center;
              display: flex;
              align-items: center;
              justify-content: center;
          }

          &__indicator {
              position: absolute;
              left: 0;
              right: 0;
              top: 0;
              bottom: 0;
              background: white;
              transform-origin: left center;
              transition: transform 0.95s;
          }
      }
  }

  &__filter-svg {
      height: 0;
      width: 0;
  }

  p {
      position: relative;
      font-size: 1.8em;
      font-weight: 300;
      margin: 0;
      padding-bottom: 0.3em;
      user-select: none;

      &.Voting__small-text {
          display: flex;
          align-items: center;
          font-size: 1.3em;
          line-height: 1.5em;
      }
  }

  button.Voting__unpop {
    background: none;
    color: white;
    margin-right: 0.3em;
    opacity: 0.3;
    &:hover {
        opacity: 1;
    }
  }

  &__small-text {
    &--unpop {
        margin-top: 0.6em;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
    }
  }

    &__svg {
        position: relative;
        width: 100%;
          min-width: 1300px;
        height: 600px;
        // padding-top: 9em;
        overflow: visible;
        z-index: 100;
      transition: all 0.6s ease-out;

      &-wrapper {
        // width: 100%;
      }

      &-container {
        // width: 100%;
        padding: 0 6em;
      }

    //   @media (max-width: 1400px) {
    //     &-wrapper {
    //         width: 100%;
    //         width: 100%;
    //         overflow-x: auto;
    //         overflow-y: visible;
    //       }

    //       &-container {
    //         margin-top: 10em;
    //           padding: 0 6em;
    //         width: 100%;
    //     }

    //   }
    }

    &__pin {
        margin-top: 0.3em;
        position: absolute;
        display: inline-block;
        vertical-align: middle;
        margin-right: 0.2em;
        margin-bottom: -0.1em;
        margin-left: -2.1em;
        // cursor: grab;
        z-index: 100;

        svg {
            fill: white;
            width: 2em;
        }

        &--is-active svg {
            fill: #7a3149;
            fill: pink;
        }

        &:hover {
          svg {
            // fill: pink;
          }
        }
    }

    &__background {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        // overflow: hidden;
        pointer-events: none;
    }

    @keyframes floatBy {
          0% {transform: translateX(0)}
        100% {transform: translateX(-130vw)}
    }

    &__cloud {
        position: absolute;
        top: 16em;
        right: -30em;
        width: 6.3em;
        height: 3em;
        fill: white;
        opacity: 0.5;
        animation: floatBy 10s ease-out;
        animation-iteration-count: infinite;

        svg {
            width: 10em;
        }

        @for $i from 1 through 90 {
            &:nth-of-type(#{$i}) {
                top: #{random(3000)}px;
                animation-delay: #{random(50)}s;
                animation-duration: #{random(130) + 20}s;
                width: #{random(20) + 9}em;
                height: #{random(20) + 9}em;
                @if $i % 2 == 0 {
                    filter: url(#blur);
                    // z-index: 100;
                } @else {
                    filter: url(#blur-more);
                    z-index: 1;
                }
            }
        }


        &--default {
            background: #fff;
            border-radius: 200px;

            &:before, &:after {
                content: '';
                position: absolute;
                background: #fff;
                width: 50%;
                height: 100%;
                position: absolute;
                top: -40%;
                left: 12%;
                border-radius: 100px;
            }

            &:after {
                width: 30%; height: 60%;
                top: -20%;
                left: auto;
                right: 8%;
            }
        }
    }

    button.Voting__pop-mode {
        background: #262262;
        color: white;
        font-size: 0.9em;
        margin-right: 0.6em;

        &--is-active {
            background: #7a3149;
            color: white;
        }
    }

    &__pop {
        @keyframes pop {
              0% {stroke-dashoffset:    0  }
            100% {stroke-dashoffset: -300px}
        }

        stroke-width: 20;
        stroke-linecap: round;
        stroke: white;
        stroke-dasharray: 30px 50px;
        stroke-dashoffset: 0;
        pointer-events: none;
        animation: pop 1s ease-out;
        animation-fill-mode: forwards;
        animation-delay: 0.5s;
    }

    &__heads {
        padding: 0 6em;
        display: flex;
        position: relative;
        transition: all 0.6s ease-out;
        z-index: 50;
        min-width: 50em;
        width: 90%;

        &-wrapper {
            padding-top: 2em;
            width: 100%;
            max-width: 1600px;
            margin: 0 auto;
            overflow: auto;
            padding-right: 3em;
        }

        &__item {
            margin-top: -2em;
            padding-top: 5em;
            flex: 1;
            transition: all 0.6s ease-out;
        }
    }

  &Head {
      flex: 1;
      width: 100%;
      height: 60em;
      max-width: 12em;
      overflow: visible;
    transition: all 0.6s ease-out;

    g {
        transition: all 0.6s ease-out;
    }

    &--is-inactive {

        .VotingHead__head {
            cursor: default;
            opacity: 0.9;
        }
    }

    &--is-dragging-on {
        .VotingHead__head {
            transform: scale(1.06) rotate(13deg);

            & + .VotingHead__text {
                opacity: 1;
            }
        }
    }
    &--is-popped {
        pointer-events: none;
        filter: grayscale(100);

        .VotingHead__head {
            @keyframes popOut {
                0% {transform: scale(1)}
                90% {transform: scale(1.3); opacity: 1}
                100% {transform: scale(3); opacity: 0}
            }
            animation: popOut 1.5s;
            animation-fill-mode: forwards;
            cursor: default;
            // transform: scale(0.6);

            & + .VotingHead__text {
                text-decoration: line-through;
            }

            .mouth {
                transform: scaleY(-1);
                // transform: rotate(180deg);
            }
        }
    }
    // &--iteration-0,
    &--iteration-1 {
        @keyframes popIn {
              0% {transform: scale(1)}
             70% {transform: scale(1.3)}
            100% {transform: scale(1)}
        }

        .eye {
            animation: popIn 0.6s ease-out;
            animation-delay: 0.6s;
            animation-fill-mode: forwards;

            &--left {
                transform-origin: 62px 160px;
            }
            &--right {
                transform-origin: 178px 160px;
            }
        }
    }

    &__bobbler {
      @keyframes bobbleAbout {
        0% {transform: translate(0,  0)}
       10% {transform: translate(-10px,  20px)}
       20% {transform: translate( 30px, -10px)}
       40% {transform: translate(-30px, -30px)}
       55% {transform: translate(-10px,  20px)}
       70% {transform: translate( 10px,  30px)}
       85% {transform: translate( 16px,  -2px)}
      100% {transform: translate(0, 0)}
    }

      animation: bobbleAbout 10s linear;
      animation-iteration-count: infinite;
      transition: all 0.6s linear;
    }

    &--nth-1 { .VotingHead__bobbler { animation-delay: 4.2s; animation-duration: 11.4s; }}
    &--nth-2 { .VotingHead__bobbler { animation-delay: 2.3s; animation-duration: 12s; }}
    &--nth-3 { .VotingHead__bobbler { animation-delay: 0.8s; animation-duration: 13s; }}
    &--nth-4 { .VotingHead__bobbler { animation-delay: 1.3s; animation-duration: 11.5s; }}
    &--nth-5 { .VotingHead__bobbler { animation-delay: 0.4s; animation-duration: 11.3s; }}
    &--nth-6 { .VotingHead__bobbler { animation-delay: 1.2s; animation-duration: 12.5s; }}
    &--nth-7 { .VotingHead__bobbler { animation-delay: 0.5s; animation-duration: 13.2s; }}
    &--nth-8 { .VotingHead__bobbler { animation-delay: 0.9s; animation-duration: 12.1s; }}
    &--nth-9 { .VotingHead__bobbler { animation-delay:   0s; animation-duration: 11.6s; }}

    .mouth {
        transform-origin: 120px 195px;
        transition: all 0.5s ease-out;
    }

    &__text,
    &__score {
        fill: white;
        opacity: 0.6;
        text-transform: uppercase;
        font-size: 2em;
        letter-spacing: 0.1em;
        text-anchor: middle;
        font-weight: 600;
        transition: all 1s ease-out;
        user-select: none;
    }

    &__score {
        opacity: 1;

        &__hype {
            overflow: visible;

            @keyframes hypeIn {
                  0% {transform: scale(0); fill-opacity: 0}
                70% {transform: scale(1.6); fill-opacity: 0.6}
                100% {transform: scale(0); fill-opacity: 0}
            }

            circle {
                fill-opacity: 0.3;
                transform-origin: center center;
                fill: white;
                animation: hypeIn 0.3s ease-out;
                animation-fill-mode: forwards;
            }
        }
    }

    &__inactive-text {
        fill: pink;
        opacity: 0.6;
        // text-transform: uppercase;
        font-size: 2em;
        // letter-spacing: 0.1em;
        text-anchor: middle;
        // font-weight: 600;
        transition: all 1s ease-out;
        user-select: none;
        fill-opacity: 0;
    }

    &:hover {
        .VotingHead__inactive-text {
            fill-opacity: 1;
        }
    }

    &__tail {
        fill: none;
        stroke: white;
        stroke-width: 2;
        transition: all 1s ease-out;
    }

    &__head {
        cursor: pointer;
        // transform-origin: 4.2% 50%;
        transform-origin: center 50%;
        transition: all 0.3s ease-out;

        &:hover {
            transform: scale(1.06) rotate(6deg);

            & + .VotingHead__text {
                opacity: 1;
            }
        }
    }

    &__cross-out {
        @keyframes crossOut {
              0% {stroke-dashoffset: 300px}
            100% {stroke-dashoffset: 0}
        }

        stroke: #771e3f;
        mix-blend-mode: color-burn;
        opacity: 0.7;
        stroke-width: 60;
        stroke-linecap: round;
        stroke-dasharray: 300px 300px;
        stroke-dashoffset: 300px;
        pointer-events: none;
        animation: crossOut 0.3s ease-out;
        animation-fill-mode: forwards;
    }

    &__pop-use {
      @keyframes popFade {
            0% { stroke-opacity: 1  ; stroke-dashoffset:    0  ; }
           50% { stroke-opacity: 1  ; }
          100% { stroke-opacity: 0.3; stroke-dashoffset: -300px; }
      }

      stroke-width: 20;
      stroke-linecap: round;
      stroke: white;
      stroke-dasharray: 30px 50px;
      stroke-dashoffset: 0;
      stroke-opacity: 0;
      pointer-events: none;

      animation: popFade 1.6s ease-out;
      animation-fill-mode: forwards;
      animation-delay: 1.6s;
    }
  }
}
