.Modal {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    pointer-events: none;

    &--is-open {
        pointer-events: all;
    }

    &--theme {
        &-light {
            .Modal__content {
                background: white;
            }
        }
    }

    &__background {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: rgba(#175898, 0.9);
        cursor: pointer;
        z-index: 1;
        animation: fadeIn 0.3s ease-out;
        animation-fill-mode: forwards;

        &-leave {
            animation: fadeOut 0.3s ease-out;
            opacity: 0;
        }
    }

    &__content {
        position: relative;
        max-width: 90vw;
        max-height: 90vh;
        // background: #e3edfa;
        background: white;
        border: 1px solid #f4f4f4;
        // padding: 2em 3em;
        z-index: 10;
        animation: slideUp 0.2s ease-out;
        box-shadow: 0 4px 12px 1px rgba(#252150, 0.5);
        animation-fill-mode: forwards;
        border-radius: 10px;
        // overflow: auto;

        @keyframes modalSlideOut {
            0% { opacity: 1; transform: translate3d(0, 0, 0); }
            100% { opacity: 0; transform: translate3d(0, 7vh, 0); }
        //   100% { opacity: 0; transform: translate3d(0, 30vh, 0) rotate(-10deg); }
        //   100% { opacity: 0; transform: translate3d(0, 33vh, 0) rotate(-20deg); }
        }

        @media (max-width: 550px) {
            max-width: 93vw;
            width: 93vw;
            // padding: 2em 3em;
        }
        @media (max-width: 500px) {
            max-height: 100vh;
            height: 100vh;
            max-width: 100vw;
            width: 100vw;
            padding: 2em 3em;
            border-radius: 0;
        }

        &-leave {
            animation: modalSlideOut 0.3s ease-out;
            opacity: 0;
        }
    }

    &__close-button {
        position: absolute;
        right: 1.5em;
        top: 1.5em;
        color: #bbbbc2;
        cursor: pointer;
        transition: color 0.3s ease-out;
        z-index: 10;
        width: 14px;

        &:hover {
            color: #b0b2bd;
        }
    }
}
