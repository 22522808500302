.App {
  text-align: center;
  min-height: 100vh;
  background: linear-gradient(
      to top,
      #01ACED,
      #175898,
      #191533,
      // #01ACED,
      // #175898,
      // #252150,
  );
  // background: #175898;
  color: white;
  width: 100%;
  min-height: 100vh;
  background-size: 100vw 3000px;
  background-position: center center;
  background-repeat: repeat-x;

  &__banner {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    border-bottom: 6px solid pink;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
      padding: 0.8em 6em;
      background: #fff;
      color: #262262;
      // width: 100%;
      text-align: center;
      font-weight: 350;

      a {
          font-weight: 600;
          color: #262262;
      }

    @media (max-width: 900px) {
      padding: 1em 2em;
    }
  }

  &__content {
      // padding: 0 6em 6em;
      padding-top: 3em;
      @media (max-width: 900px) {
        padding-top: 7em;
      }
  }

  h1 {
      margin-bottom: 0.26em;
      font-size: 5em;
      text-align: left;
      line-height: 1em;
      font-weight: 100;
      user-select: none;
      letter-spacing: -0.022em;

      b {
          font-weight: 900;
      }
  }
  p {
    text-align: left;
  }

  &__buttons {
    position: absolute;
    top: 6rem;
    right: 6rem;
    display: flex;
    align-items: center;

    @media (max-width: 900px) {
      top: 9rem;
    }
  }

  &__button {
    position: relative;
    appearance: none;
    padding: 0.3em;
    background: none;
    color: white;
    border: none;
    outline: none;
    box-shadow: none;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 0.09em;
    font-size: 1.1em;
    cursor: pointer;
    opacity: 0.6;
    transition: opacity 0.3s ease-out;
    z-index: 1000;

    &:hover {
      opacity: 1;
    }

    & + & {
      margin-left: 1.6em;
    }
  }

  &__text {
    padding: 0 6em;

    @media (max-width: 900px) {
      padding: 0 2em;
      font-size: 0.8em;
      line-height: 2.1em;
    }
  }
}

@keyframes bounce {
  0%, 20%, 53%, 80%, 100% {
    transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    transform: translate3d(0,0,0);
  }

  40%, 43% {
    transition-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
    transform: translate3d(0, -30px, 0);
  }

  70% {
    transition-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
    transform: translate3d(0, -15px, 0);
  }

  90% {
    transform: translate3d(0,-4px,0);
  }
}

.Logo {
  display: block;
  margin: 6em 6em 1em;
  width: 30em;
  overflow: visible;

  &__balloon {
      animation-name: bounce;
      transform-origin: center bottom;
      animation-duration: 2s;
      animation-delay: 0.3s;
      animation-fill-mode: both;

    // transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }

  @media (max-width: 900px) {
    margin: 6em 2em 1em;
    width: 80%;
  }

  // &:hover {
  //   .Logo__balloon {
  //     transform: translateY(-21px);

  //     &--right {
  //       transition-delay: 0.2s;
  //     }
  //   }
  // }
}