// @import "src/styles/lib";

.CalendarEventModal {
  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1em 2em;

    h1 {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  &__links {
    display: flex;
    align-items: center;
    margin-bottom: 1em;
  }

  &__link {
    padding: 0.6em 1em;
    font-weight: 600;
    text-decoration: none;
    color: #175898;
  }

  &__modal__content {
  }
}
