.Icon {
    fill: currentColor;
    transition: transform 0.3s ease-out;
    transform-origin: center center;

    &__background {
        fill: transparent;
    }

    &--direction {
        &-n  { transform: rotate(180deg); }
        &-ne { transform: rotate(225deg); }
        &-e  { transform: rotate(270deg); }
        &-se { transform: rotate(315deg); }
        &-s  { transform: rotate(  0deg); }
        &-sw { transform: rotate( 45deg); }
        &-w  { transform: rotate( 90deg); }
        &-nw { transform: rotate(135deg); }
    }

    // &--has-triangle,
    // &--has-circle {
    //     fill: white;
    // }

    &__triangle,
    &__circle {
        fill: white;
    }

    &__triangle {
        transform-origin: 21% 30%;
    }
}
