// @import "src/styles/lib";

.Share {
  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__icons {
    margin-top: -5.5em;
    margin-bottom: 1.5em;
    display: flex;
    align-items: center;
  }

  &__icon {
    margin: 0.5em;
    cursor: pointer;
    transition: all 0.2s ease-out;

    &__link {
      color: #233691;
      appearance: none;
      background: none;
      box-shadow: none;
      transition: all 0.2s ease-out;


      &:hover {
        color: black;
        transform: scale(1.2);
      }

      &:active,
      &:focus {
        border-color: #233691;
        outline: none;
      }
    }

    border-radius: 100%;
    border: 3px solid transparent;
    padding: 0;

    svg {
      display: block;
    }
  }

  &__toggle {
    display: block;
    position: fixed;
    top: 6.3em;
    right: 0;
    background: white;
    padding: 1em;
    color: #233691;
    appearance: none;
    box-shadow: none;
    border: 3px solid transparent;
    border-radius: 100%;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    transition: all 0.2s ease-out;
    cursor: pointer;
    z-index: 200;

    &:hover {
      color: black;
      transform: scale(1.2);
    }

    &:active,
    &:focus {
      border-color: #233691;
      outline: none;
    }

    @media (max-width: 900px) {
      top: 9em;
    }
  }

  &__modal__content {
    background: none;
    border: none;

    .Modal__close-button {
      margin-right: -4em;
    }
  }

  &__image {
    margin: -25px;
    margin-top: -43px;
    max-width: 50em;
    max-height: 70vh;
  }
}
