// @import "src/styles/lib";

.Score {
    // overflow: auto;

    p {
        position: relative;
        font-size: 1.8em;
        font-weight: 300;
        margin: 0;
        padding-bottom: 0.3em;
        user-select: none;
        letter-spacing: 0;
    }


    &__svg {
        margin-top: 2em;
        min-width: 900px;
        // min-width: 1200px;
        min-height: 400px;
        height: 70vh;
        width: 100%;
        overflow: visible;

        svg {
            overflow: visible;
        }


        @media (max-width: 900px) {
            margin: 2em -2em 0 -1.5em;
        }

        &-wrapper {
            width: 100%;
            height: 76vh;
            width: calc(100% + 6em + 60px);
            margin-right: -6em;
            margin-left: -60px;
            overflow: visible;

            @media (max-width: 900px) {
                margin-right: -2em;
                width: calc(100% + 4em);
                margin-left: -2em;
                padding-left: 2em;
                overflow: auto;
            }
      }
    }

    @keyframes fadeIn {
      from { opacity: 0; }
        to { opacity: 1; }
    }

    &__line {
        opacity: 0;
        animation: fadeIn 1.5s ease-out;
        animation-delay: 0.7s;
        animation-fill-mode: forwards;
        fill: none;
        stroke-width: 7;
        // stroke-width: 6;
        // stroke: white;
        stroke-linecap: round;
        // opacity: 0.8;
        transition: all 0.2s ease-out;
        mix-blend-mode: lighten;

        &--is-active {
            opacity: 1;
            stroke: white;
        }
    }

    &__outline {
        stroke-width: 46;
        fill: none;
        // mix-blend-mode: lighten;
    }


    &__head {
        opacity: 0;
        animation: fadeIn 1.5s ease-out;
        animation-delay: 0.7s;
        animation-fill-mode: forwards;
        transition: all 0.3s ease-out;

        &__group {
            transition: all 0.3s ease-out;
            transform-origin: center center;
        }

        &--is-active {
            &.Score__head--highlight {
                opacity: 1;

                .Score__head__group {
                    transform: scale(2);
                }
            }
        }

        &__text {
            fill: white;
            text-anchor: middle;
            font-size: 50px;
            font-weight: 700;
            letter-spacing: 0.1em;
            text-transform: uppercase;
            pointer-events: none;

            &-background {
                fill: #175898;
            }
        }

        &--highlight {
            opacity: 0;
            animation: none;
        }
    }

    &__pop {
        // fill: white;
        // stroke: white;
        // stroke: #175898;
        // stroke-width: 1.5px;
        // stroke-opacity: 0.6;
        // fill-opacity: 0.6;
        // mix-blend-mode: lighten;
    }

    &__tick {
        fill: white;
        font-weight: 600;
        text-anchor: middle;

        &--y {
            text-anchor: end;
        }

        &__label {
            fill: white;
            font-weight: 600;
            text-anchor: start;
            opacity: 0.6;
            font-weight: 300;
            letter-spacing: 0.01em;
        }
    }

    &__text {
        fill: white;
        font-weight: 600;
    }
}
